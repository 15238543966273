.commentBase {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
}

.commentHeading {
    font-family: 'Patrick Hand SC', cursive;
    font-size: 0.8vw;
    color: #ffffff;
    font-weight: bold;
    text-shadow: 1px 1px #5D3D11;
    position: absolute;
    left: 50%;
    z-index: 2;
    width: 100%;
    text-align: center;
    background-color: rgba(93, 61, 17, 0.8);
    padding: 0.2vw 0.5vw;
    border-radius: 5px;
}

.comment1 {
    composes: commentBase;
    width: 10vw;
    height: 10vw;
    top: 0;
    left: 5vw;
    background-image: url('../../assets/images/comment1.png');
}

.comment1 .commentHeading {
    top: -3%;
    transform: translate(-50%, -50%) rotateZ(9.81deg);
}

.comment1 p {
    color: black;
    font-size: .9vw;
    padding: 0 1vw;
    transform: rotateZ(9.81deg);
    margin-top: -2vw;
}

.comment2 {
    composes: commentBase;
    width: 8vw;
    height: 12vw;
    top: 3vw;
    right: 7vw;
    background-image: url('../../assets/images/comment2.png');
}

.comment2 .commentHeading {
    top: -3%;
    transform: translate(-50%, -50%) rotateZ(9.81deg);
}

.comment2 p {
    color: black;
    font-size: .9vw;
    padding: 0 1.7vw;
    transform: rotateZ(9.81deg);
    margin-top: -2vw;
}

.comment3 {
    composes: commentBase;
    width: 14vw;
    height: 12vw;
    bottom: 9vw;
    left: 5vw;
    background-image: url('../../assets/images/commment3.png');
}

.comment3 .commentHeading {
    top: -3%;
    transform: translate(-50%, -50%) rotateZ(7.46deg);
}

.comment3 p {
    color: black;
    font-size: .9vw;
    padding: 5vw 1.7vw;
    padding-right: 6vw;
    transform: rotateZ(7.46deg);
    margin-top: -2vw;
}

.comment4 {
    composes: commentBase;
    width: 13vw;
    height: 19vw;
    bottom: 9vw;
    right: 3vw;
    background-image: url('../../assets/images/comment4.png');
}

.comment4 .commentHeading {
    top: -1%;
    transform: translate(-50%, -50%) rotateZ(8.33deg);
}

.comment4 p {
    color: black;
    font-size: .9vw;
    padding: 1vw 5vw;
    transform: rotateZ(-2.33deg);
    margin-top: -2vw;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .comment1 {
        width: 20vw;
        height: 20vw;
        top: 5vw;
    }

    .comment1 p {
        font-size: 1.6vw;
        margin-top: -4vw;
        margin-left: 2vw;
    }

    .comment2 {
        width: 20vw;
        height: 24vw;
        top: 9vw;
        right: 3vw;
    }

    .comment2 p {
        font-size: 2vw;
        padding: 0 4.7vw;
    }

    .comment3 {
        width: 30vw;
        height: 24vw;
        bottom: -22vw;
    }

    .comment3 p {
        font-size: 1.8vw;
        padding-right: 11vw;
    }

    .comment4 {
        width: 26vw;
        height: 38vw;
        bottom: -32vw;
        right: 1vw;
    }

    .comment4 p {
        font-size: 2vw;
        padding: 1vw 9vw;
    }
}