@font-face {
    font-family: demo;
    src: url('../../assets/fonts/Retroking.ttf');
}

@font-face {
    font-family: demo1;
    src: url('../../assets/fonts/RINGM___.TTF');
}

@font-face {
    font-family: 'Patrick Hand SC';
    src: url('../../assets/fonts/PatrickHandSC-Regular.ttf') format('truetype');
}

h1 {
    font-family: demo1;
    color: #ffffff;
    font-size: 4vw;
    line-height: 130%;
    font-weight: 800;
    text-shadow: -.2vw .2vw 0 #8F5D2D;
    -webkit-text-stroke: .5px;
    -webkit-text-stroke-color: #8F5D2D;
}

h2 {
    font-family: demo;
    color: #BB2424;
    font-size: 2.5vw;
    line-height: 100%;
    text-shadow: -.1vw .2vw 0 #E9C38D;
}

p {
    color: #5D3D11;
    font-size: 1vw;
    line-height: 120%;
}

li {
    list-style: none;
    font-size: 1.3vw;
    line-height: 120%;
    color: #5D3D11;
    list-style: decimal;
}

/* Mobile typography */
@media (max-width: 768px) {
    h1 {
        font-size: 7vw;
    }

    h2 {
        font-size: 4vw;
    }

    p {
        font-size: 2.5vw;
    }

    li {
        font-size: 2.9vw;
    }
}