/* Base layout and structure */
body {
    background: url('../assets/images/book-bg.webp') no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

/* Global utility classes */
.btn {
    border-radius: 1vw;
    font-family: demo;
    font-size: 1.1vw;
    color: #000000;
    line-height: normal;
    padding: .5vw 1.5vw;
    background: linear-gradient(90deg, #FFF84C 0%, #FF9C41 100%);
    box-shadow: 0 .5vw 1vw #FF9C41;
    transition: all .4s;
}

.btn:hover {
    border: 1px solid #AC7125;
    background: transparent;
    color: white;
    box-shadow: 0 .5vw 1vw #000000;
}

.btn1 {
    border: 1px solid #AC7125;
    background: #493112;
    border-radius: 1vw;
    font-size: 1.25vw;
    color: #ffffff;
    line-height: normal;
    padding: .5vw 1.5vw;
    transition: all .3s;
}

.btn1:hover {
    background: transparent;
}

/* Navigation styles */
nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 6vw;
    gap: 1vw;
}

nav .links {
    display: flex;
    gap: 1vw;
    margin-left: 1vw;
}

nav .links a img {
    width: 4vw;
    transition: all .2s;
    transform-origin: top;
}

nav .links a img:hover {
    filter: invert(100%);
    transform: translateY(-.2vw);
}

/* Common animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

/* Common utility classes */
.red {
    background: url('../assets/images/red-bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 2vw;
}

.red1 {
    background: url('../assets/images/red1-bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 1vw;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .btn {
        border-radius: 2vw;
        font-size: 2.7vw;
        padding: 1vw 2vw;
    }

    .btn1 {
        border-radius: 2vw;
        font-size: 2.9vw;
        padding: 1vw 2vw;
    }

    nav {
        padding: 0 6vw;
        gap: 3vw;
    }

    nav .links {
        gap: 3vw;
    }

    nav .links a img {
        width: 8vw;
    }

    .red {
        padding: 0 5vw;
    }

    .red1 {
        padding: 0 2vw;
    }
}