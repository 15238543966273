* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    cursor: default;
    font-family: "Patrick Hand SC", cursive;
    font-weight: 400;
    font-style: normal;
}

a {
    text-decoration: none;
    cursor: pointer;
}