.form {
    width: 100%;
    padding: 2vw 7vw;
    background: linear-gradient(to bottom, #34240e3e, transparent);
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1vw 0;
}

.left {
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.input1 {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vw;
}

.input1 input {
    padding: .8vw;
    font-size: 1vw;
    color: white;
    background: #34240E;
    box-shadow: none;
    text-shadow: none;
    border: 1px solid #AC7125;
    border-radius: 1vw;
    width: 100%;
}

.orText {
    color: #5D3D11;
    font-size: 1.3vw;
}

.right {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vw;
}

.boxContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.box {
    display: flex;
    align-items: center;
    gap: .8vw;
    padding: .7vw 1.5vw;
    background: #34240E;
    border: 1px solid #AC7125;
    border-radius: 1vw;
}

.radioInput {
    position: relative;
    width: 1.5vw;
    height: 1.5vw;
    background: #20160A;
    appearance: none;
    cursor: pointer;
    border-radius: .5vw;
}

.radioInput:checked {
    background: url('../../assets/images/tick.png') no-repeat;
    background-size: contain;
    background-position: center;
}

.box li {
    list-style: none;
    font-size: 1.2vw;
    color: #F5B75B;
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vw;
    padding: 1vw 0;
}

.inputContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 1300px;
}

.messageInput {
    width: 100%;
    box-sizing: border-box;
    padding: .8vw;
    padding-right: 80px;
    font-size: 1vw;
    color: white;
    background: #34240E;
    border: 1px solid #AC7125;
    border-radius: 1vw;
}

.submitBtn {
    position: absolute;
    right: 2px;
    top: 2px;
    height: calc(100% - 4px);
    width: 70px;
    border: none;
    background-color: #5D3D11;
    color: #FAE901;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.submitBtn:hover {
    background-color: #4B3910;
}

/* Reuse the global red1 class background but with local styling */
.redLabel {
    composes: red1 from global;
    font-size: 1.3vw;
    color: #DC3620;
}

@media (max-width: 768px) {
    .form {
        padding-top: 23vw;
    }

    .top {
        flex-direction: column;
        align-items: center;
        gap: 5vw;
    }

    .left {
        width: 100%;
    }

    .input1 input {
        padding: 1.5vw 2vw;
        font-size: 3vw;
        border-radius: 2vw;
    }

    .orText {
        font-size: 3.5vw;
    }

    .right {
        width: 100%;
        gap: 2vw;
    }

    .box {
        gap: 1.9vw;
        padding: 1.5vw 2.5vw;
        border-radius: 2vw;
    }

    .radioInput {
        width: 5vw;
        height: 5vw;
        border-radius: 1vw;
    }

    .box li {
        font-size: 3.3vw;
    }

    .bottom {
        gap: 2vw;
        padding: 3vw 0;
    }

    .messageInput {
        padding: 1.5vw 2vw;
        font-size: 3vw;
        border-radius: 2vw;
    }

    .redLabel {
        font-size: 3vw;
    }
}