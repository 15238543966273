@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.hero {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.glow {
    width: 100%;
    top: 0;
    height: 30vw;
    position: absolute;
    z-index: 2;
    mix-blend-mode: screen;
}

/* Update heroImg with enhanced animation properties */
.heroImg {
    width: 10%;
    z-index: 3;
    animation: bounce 2s ease-in-out infinite;
    transform-origin: center bottom; /* Makes the bounce look more natural */
    will-change: transform; /* Optimizes animation performance */
    position: relative; /* Ensures proper stacking */
}
.content {
    width: 70%;
    background: url('../../assets/images/Book.webp') no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 6vw 10vw;
    padding-right: 8vw;
    padding-bottom: 15vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    margin-top: -3vw;
}

.left {
    width: 45%;
    text-align: center;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5vw;
    padding-top: 5vw;
}

.typewriter {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.typewriter span {
    opacity: 0;
    transition: opacity 0.5s;
}

.right {
    width: 45%;
    padding: 0 3%;
    padding-right: 0;
}

.heading {
    text-align: center;
    font-size: 1.7vw;
    margin-bottom: 2vw;
    padding-right: 3vw;
}

.moments {
    width: 100%;
    height: 20vw;
    overflow-y: scroll;
    padding-right: 5%;
}

.moments::-webkit-scrollbar {
    width: .5vw;
}

.moments::-webkit-scrollbar-track {
    background: rgba(219, 189, 148, 0.4);
    border-radius: 20vw;
}

.moments::-webkit-scrollbar-thumb {
    background: #DBBD94;
    border-radius: 20vw;
}

.moments li {
    margin-bottom: 2vw;
}

.redBanner {
    composes: red from global;
    z-index: 2;
    width: 100%;
    text-align: center;
}

.redBanner p {
    font-size: 1.3vw;
    text-align: center;
    color: #DC3620;
}

@media (max-width: 768px) {
    .hero {
        padding-top: 5vw;
    }

    .glow {
        height: 70vw;
    }

    .heroImg {
        width: 20%;
    }

    .content {
        width: 100%;
        padding: 10vw;
        padding-right: 8vw;
        padding-bottom: 25vw;
    }

    .heading {
        font-size: 3vw;
        margin-bottom: 3vw;
    }

    .moments {
        height: 45vw;
    }

    .moments::-webkit-scrollbar {
        width: 1.2vw;
    }

    .redBanner {
        position: relative;
        top: -5vw;
    }

    .redBanner p {
        font-size: 3vw;
        line-height: 100%;
    }
}