.section {
    background: linear-gradient(to top, #34240edf, transparent);
    padding: 5vw 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2vw;
}

.title {
    font-family: demo1;
    color: #ffffff;
    font-size: 4vw;
    line-height: 130%;
    font-weight: 800;
    text-shadow: -.2vw .2vw 0 #8F5D2D;
    -webkit-text-stroke: .5px;
    -webkit-text-stroke-color: #8F5D2D;
    margin-bottom: 3vw;
}

.tokenDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2vw;
}

.tokenDetail {
    width: calc(33.333% - 2vw);
    margin-bottom: 2vw;
    transition: transform 0.3s ease;
    text-align: center;
}

.tokenDetail:hover {
    transform: translateY(-10px);
}

.tokenIcon {
    height: 5vw;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.totalSupply .tokenIcon {
    
}

.liquidityPool .tokenIcon {
 
}

.teamAllocation .tokenIcon {
    
}

.presale .tokenIcon {
   
}

.marketing .tokenIcon {
   
}

.airdrop .tokenIcon {
    
}

.tokenHeading {
    composes: red1 from global;
    font-family: 'Patrick Hand SC', cursive;
    font-size: 1.5vw;
    margin-bottom: 1vw;
    text-align: center;
}

.tokenValue {
    font-family: 'Patrick Hand SC', cursive;
    font-size: 1.2vw;
    color: #ffffff;
}

@media (max-width: 768px) {
    .title {
        font-size: 7vw;
    }

    .tokenDetails {
        flex-direction: column;
        align-items: center;
    }

    .tokenDetail {
        width: 80%;
        margin-bottom: 1vw;
    }

    .tokenIcon {
        height: 10vw;
    }

    .tokenHeading {
        font-size: 3vw;
    }

    .tokenValue {
        font-size: 2.5vw;
    }
}